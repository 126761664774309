/* @import url("https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,400;9..40,500;9..40,600;9..40,700;9..40,800;9..40,900;9..40,1000&family=Roboto+Condensed:wght@400;700&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

/*----------------------------------------------*
 General CSS
/*----------------------------------------------*/

*,
::after,
::before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  /* border: 0; */
  outline: 0;
}

html,
body,
form,
fieldset,
p,
div,
h1,
h2,
h3,
h4,
h5,
h6 {
  -webkit-text-size-adjust: none;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
  clear: both;
}

html {
  font-family: "Inter", sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  background: #fff;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-weight: normal;
}
textarea {
  resize: none;
}
a {
  background: transparent;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
}

a:active {
  outline: 0;
}

a:active,
a:hover,
a:focus {
  text-decoration: none;
  outline: none;
  box-shadow: none;
}

*:focus {
  outline: none;
  box-shadow: none;
}
.btn:focus {
  outline: none;
  box-shadow: none;
}
.btn-close:focus {
  box-shadow: none;
}
.form-control:focus {
  box-shadow: none;
  border-color: #dee2e6;
}
b,
strong {
  font-weight: bold;
}
audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}
video {
  width: 100%;
  height: 100%;
}
audio:not([controls]) {
  display: none;
  height: 0;
}
svg:not(:root) {
  overflow: hidden;
}
hr {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
}
pre {
  overflow: auto;
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
}

mark {
  background: #ff0;
  color: #111111;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
td,
th {
  padding: 0;
}
ul,
ol {
  list-style: none outside none;
}

img {
  vertical-align: middle;
  border: 0;
  max-width: 100%;
}

ul,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

/* ////////////////////////////////////////////////////////  style start  /////////////////////// */

.h-100 {
  height: 100vh;
}
/* Main style start */
.body-bg-color {
  background: #f4f8fe;
}

.body-top-title {
  color: #000;
  /* font-family: var(--primary-font); */
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 100%;
}
/* .primary-bg-blue {
  background-color: #dd0631;
}
.primary-bg-green {
  background-color: var(--primary-green);
}
.hover-bg-green:hover {
  background-color: #35be53;
}
.blue-dark-text {
  color: var(--blue-dark);
}
.red-text {
  color: var(--primary-red);
}
.tale-td-red-text {
  color: var(--primary-red);
}
.light-orange-bg {
  background: var(--light-orange);
}
.light-red-bg {
  background: var(--light-red);
}
.light-blue-bg {
  background: var(--light-blue);
}
.text-red-2 {
  color: var(--red-2);
} */
/* Custom breadcrumb start */
.breadcrumb--common .breadcrumb-item {
  font-family: var(--primary-font);
  /* font-size: var(--fs-1); */
  font-size: 11.606px;
  line-height: 19.895px;
}
.breadcrumb--common .breadcrumb-item > a {
  color: #5c5c5c;
  font-weight: 500;
  line-height: 100%;
}

.breadcrumb--common .breadcrumb-item .active {
  font-weight: 600;
}
.page-topbar-title {
  /* font-family: var(--primary-font); */
  color: #dd0631;
  font-size: 1rem;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: -0.564px;
  line-height: 34.817px;
}

/* Custom breadcrumb end */
.page-top-bar-left-content {
  display: flex;
}
/* Topbar search start */
.page-top-bar-right-content {
  width: 100%;
  height: 51px;
  border-radius: 24.869px;
  background: #fff;
  box-shadow: 11.60565px 14.09257px 33.159px 3.3159px rgba(112, 144, 176, 0.08);
  -webkit-border-radius: 24.869px;
  -moz-border-radius: 24.869px;
  -ms-border-radius: 24.869px;
  -o-border-radius: 24.869px;
  display: flex;
  align-items: center;
  padding: 8px;
}

.search-container {
  position: relative;
  display: inline-block;
}

.search-container .form-control {
  width: 100%;
  height: 38px;
  padding: 5px 10px 5px 34px;
  border: 1px solid #c7e4ff;
  background: #f4f7fe;
  border-radius: 30px;
  font-size: 14px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
  display: flex;
  align-items: center;
}

.search-container button[type="submit"] {
  position: absolute;
  top: -2px;
  left: 0;
  padding: 8px 15px;
  background-color: transparent;
  border: none;
  border-radius: 0 5px 5px 0;
  cursor: auto;
}

.form-control:focus,
.btn:focus {
  outline: none;
  box-shadow: none;
}
.header-item {
  /* height: 70px; */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
/* Topbar search end */
.custom-animated-dropdown .dropdown-menu {
  -webkit-box-shadow: 0 5px 10px rgba(30, 32, 37, 0.12);
  box-shadow: 0 5px 10px rgba(30, 32, 37, 0.12);
  -webkit-animation-name: DropDownSlide;
  animation-name: DropDownSlide;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  position: absolute;
  z-index: 1000;
  border: none;
}
.custom-animated-dropdown .dropdown-menu .dropdown-item:hover {
  color: #dd0631;
}
@keyframes DropDownSlide {
  100% {
    margin-top: -1px;
  }

  0% {
    margin-top: 8px;
  }
}
/* Topbar style start  */
.page-topbar {
  position: fixed;
  top: 0px;
  right: 0;
  left: 0;
  z-index: 1002;
  background: #fff;
  box-shadow: 11.60565px 14.09257px 33.159px 3.3159px rgba(112, 144, 176, 0.08);
  transition: all 0.1s ease-out;
  border: 1px solid none;
  -moz-transition: all 0.1s ease-out;
  -ms-transition: all 0.1s ease-out;
  -o-transition: all 0.1s ease-out;
  -webkit-transition: all 0.1s ease-out;
}
.navbar-header {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 0.75rem;
}
.hamburger-icon {
  width: 20px;
  height: 14px;
  position: relative;
  cursor: pointer;
  display: inline-block;
}
.header-item {
  /* height: 70px; */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.topbar-user .btn {
  padding-left: 0;
  padding-right: 0;
}
.collapsed-navbar-menu.mobile-sidebar {
  width: 242px;
}

/* Hamburger style start */
.hamburger-icon {
  width: 20px;
  height: 14px;
  position: relative;
  cursor: pointer;
  display: inline-block;
}
.hamburger-icon span {
  background-color: #878a99;
  position: absolute;
  border-radius: 2px;
  transition: 0.3s cubic-bezier(0.8, 0.5, 0.2, 1.4);
  width: 100%;
  height: 2px;
  display: block;
  left: 0;
  -moz-transition: 0.3s cubic-bezier(0.8, 0.5, 0.2, 1.4);
  -ms-transition: 0.3s cubic-bezier(0.8, 0.5, 0.2, 1.4);
  -o-transition: 0.3s cubic-bezier(0.8, 0.5, 0.2, 1.4);
  -webkit-transition: 0.3s cubic-bezier(0.8, 0.5, 0.2, 1.4);
}
.hamburger-icon span:nth-child(1) {
  top: 0;
  width: 80%;
}
.hamburger-icon span:nth-child(2) {
  top: 6px;
}
.hamburger-icon span:nth-child(3) {
  bottom: 0;
  width: 60%;
}

.vertical-menu-btn:hover .hamburger-icon:not(.open) span:nth-child(1) {
  top: -1px;
}
.vertical-menu-btn:hover .hamburger-icon:not(.open) span:nth-child(3) {
  bottom: -1px;
}
.vertical-menu-btn.btn:first-child:active {
  border-color: #fff !important;
}
.vertical-menu-btn.btn:focus {
  border-color: #fff !important;
}
.hamburger-icon.open {
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
}

.hamburger-icon.open span:nth-child(1) {
  left: 1px;
  top: 5px;
  width: 20px;
  transform: rotate(90deg);
  transition-delay: 150ms;
  -webkit-transition-delay: 150ms;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
}

.hamburger-icon.open span:nth-child(2) {
  left: 3px;
  top: 13px;
  width: 10px;
  transform: rotate(45deg);
  -webkit-transition-delay: 50ms;
  transition-delay: 50ms;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
}

.hamburger-icon.open span:nth-child(3) {
  left: 9px;
  top: 13px;
  width: 10px;
  transform: rotate(-45deg);
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
}
/* Hamburger style end */

.collapsed-navbar-menu {
  position: absolute;
  width: 70px !important;
}

/* Topbar style end */

/* Sidebar menu start */

/* width */

.scrollbar {
  height: calc(100vh - 100px);
  overflow-y: auto;
  width: 100%;
}
.scrollbar::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
.scrollbar::-webkit-scrollbar-track {
  background: #a3aed0;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
}

/* Handle */
.scrollbar::-webkit-scrollbar-thumb {
  background: #dd0631;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
}

/* Handle on hover */
.scrollbar::-webkit-scrollbar-thumb:hover {
  background: #dd0631;
}

.hover-dropdown-collapse-prevent .scrollbar-dropdown {
  height: 300px;
  overflow-y: auto;
}
.hover-dropdown-collapse-prevent .scrollbar-dropdown::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
.hover-dropdown-collapse-prevent .scrollbar-dropdown::-webkit-scrollbar-track {
  background: #a3aed0;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
}
.hover-dropdown-collapse-prevent .scrollbar-dropdown::-webkit-scrollbar-thumb {
  background: #dd0631;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
}
.hover-dropdown-collapse-prevent
  .scrollbar-dropdown::-webkit-scrollbar-thumb:hover {
  background: #dd0631;
}

.scrollbar--sidebar {
  height: calc(100vh - 200px);
  overflow-x: hidden;
}
.scrollbar--common-table {
  height: auto;
}
.sidebar {
  width: 242px;
  z-index: 1002;
  background: #fff;
  box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.25);
  bottom: 0;
  margin-top: 0;
  position: fixed;
  top: 0;
  padding: 0 0 calc(70px + 25px) 0;
  transition: all 0.1s ease-out;
  -webkit-transition: all 0.1s ease-out;
  -moz-transition: all 0.1s ease-out;
  -ms-transition: all 0.1s ease-out;
  -o-transition: all 0.1s ease-out;
}
.sidebar-brand {
  padding: 0 1.3rem;
  text-align: center;
  transition: all 0.1s ease-out;
  -webkit-transition: all 0.1s ease-out;
  -moz-transition: all 0.1s ease-out;
  -ms-transition: all 0.1s ease-out;
  -o-transition: all 0.1s ease-out;
}
.logo-light {
  display: inline-block;
}
.logo {
  line-height: 106px;
}

.sidebar-nav .nav-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.225rem 0.125rem;
  margin: 0.425rem 0.875rem;
  color: #5c5c5c;
  font-size: 0.9375rem;
  /* font-family: var(--primary-font); */
  font-weight: 700;
  line-height: 26.719px;
  letter-spacing: -0.24px;
}
.sidebar-nav .nav-link svg {
  display: inline-block;
  min-width: 2.3rem;
}

.toggle-xs-sidebar .sidebar-brand {
  padding-bottom: 0px;
}

.toggle-xs-sidebar.collapsed-navbar-menu {
  padding-bottom: 0px;
}

.sidebar-menu-text {
  margin-left: 8px;
}
.sidebar-nav .nav-link {
  margin-right: 5px;
}
.sidebar-menu-text {
  display: flex;
}
.sidebar-nav .nav-link {
  border-radius: 0px 30px 30px 0px;
  -webkit-border-radius: 0px 30px 30px 0px;
  -moz-border-radius: 0px 30px 30px 0px;
  -ms-border-radius: 0px 30px 30px 0px;
  -o-border-radius: 0px 30px 30px 0px;
}
.sidebar-nav .nav-link svg {
  fill: #4a4a4a;
}
.sidebar-nav .nav-link:hover svg {
  fill: #dd0631;
}
.sidebar-nav .nav-link:hover {
  color: #dd0631;
}
.sidebar-nav .nav-link.active {
  color: #dd0631;
}
.sidebar-nav .nav-link.active svg {
  fill: #dd0631;
}
.vertical-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(33, 37, 41, 0.35);
  z-index: 1001;
  display: none;
}
/* Sidebar menu end */

/* Sidebar dropdown menu start */

.dropdown-content {
  padding-left: 2.8rem;
}
.dropdown-content2 {
  padding-left: 0.875rem;
}
.dropdown-content .nav-link {
  font-size: 0.75rem;
  padding-right: 0;
  /* font-family: var(--primary-font); */
  color: #5c5c5c;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: -0.24px;
}

.dropdown-content .dropdown-content2 .nav-link {
  font-size: 0.75rem;
  padding-right: 0;
  /* font-family: var(--primary-font); */
  color: #5c5c5c;
  font-weight: 500;
  line-height: 10.719px;
  letter-spacing: -0.24px;
}
.dropdown-content,
.dropdown-content2 {
  display: none;
}
/* Sidebar dropdown menu end */

/* Sidebar buton logout start */
.btn-logout-wrap {
  padding: 0.75rem;
}
.sidebar-btn-logout {
  background: #dd0631;
  width: 132px;
  height: 42px;
  border: none;
  color: #fff;
  margin: auto;
  border-radius: 16px;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  -ms-border-radius: 16px;
  -o-border-radius: 16px;
  transition: 0.6s;
  -webkit-transition: 0.6s;
  -moz-transition: 0.6s;
  -ms-transition: 0.6s;
  -o-transition: 0.6s;
}
.sidebar-btn-logout.btn:hover,
.sidebar-btn-logout.btn:focus {
  background: #fc1140;
  color: #fff;
}
.logout-btn-small {
  margin: auto;
}
.logout-btn-small svg {
  color: #4a4a4a;
}
.logout-btn-small:hover svg {
  color: #fc1140;
}
.bg-transparent {
  background: transparent;
}
/* Sidebar buton logout end */

/* Page content start */
.page-content {
  padding: calc(70px + 1.5rem) calc(1.5rem * 0.5) 60px calc(1.5rem * 0.5);
  margin-top: 27px;

}

/* Page title style start */
.page-title {
  margin: 17px 0px 37px;
}
.common-card .container {
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;
}

/* Dashboard content start */
.avatar-title {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #405189;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-weight: 500;
  height: 100%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
}
.avatar-sm {
  height: 3rem;
  width: 3rem;
}
.card-animate {
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.card-animate:hover {
  -webkit-transform: translateY(calc(-1.5rem / 5));
  transform: translateY(calc(-1.5rem / 5));
  -webkit-box-shadow: 0 5px 10px rgba(30, 32, 37, 0.12);
  box-shadow: 0 5px 10px rgba(30, 32, 37, 0.12);
}
.bg-light-red {
  background: #fce6ea;
}
.text-primary-red {
  color: #dd0631;
}
.submenu-dropdown-icon {
  transition: 0.6s;
  -webkit-transition: 0.6s;
  -moz-transition: 0.6s;
  -ms-transition: 0.6s;
  -o-transition: 0.6s;
}
.rotate-submenu-icon {
  transition: 0.6s;
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -webkit-transition: 0.6s;
  -moz-transition: 0.6s;
  -ms-transition: 0.6s;
  -o-transition: 0.6s;
}
/* Dashboard content end */

/* Footer start */
.footer--admin {
  bottom: 0;
  padding: 20px calc(1.5rem * 0.5);
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  position: fixed;
  right: 0;
  color: #737b7b;
  left: 0;
  background: #fff;
  z-index: 3;
}
.footer--admin.left-70 {
  left: 70px;
}

.footer--admin-copyright-text {
  font-size: 0.938rem;
  color: #737b7b;
}
.privacy-policy-text {
  font-size: 0.938rem;
  line-height: 100%;
  color: #737b7b;
  text-decoration: underline;
  text-underline-offset: 0.1rem;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}
.privacy-policy-text:hover {
  color: #dd0631;
  text-decoration: underline;
}
.searchbtn{
  width: 100%;
}
.clearfiltermargin{
  margin-left: -20px;
}
.norecord{
  font-size: 18px;
  margin-top: 70px;
}
/* Footer end */
/* ////////////////////////////////////////////////////////  style end  /////////////////////// */
.landing-success-btn {
  background: #4bb543;
  width: 120px;
  border-radius: 16px;
  color: #ffffff;
  border: none;
  padding: 5px;
  padding: 7px;
  font-size: 12px;
  font-weight: 400;
}

.landing-clear-btn {
  background: #1783e9;
  width: 120px;
  border-radius: 16px;
  color: #ffffff;
  border: none;
  padding: 5px;
  padding: 7px;
  font-size: 12px;
  font-weight: 400;
  margin-left: 20px;
}

.landing-pending-btn {
  background: #f26e6e;
  width: 120px;
  border-radius: 16px;
  color: #ffffff;
  border: none;
  padding: 5px;
  font-size: 12px;
  font-weight: 400;
}
.next-btn-crcl {
  width: 160px;
  border-radius: 25px;
  background: transparent;
  color: #0a4f73;
  font-weight: 400;
  font-size: 16px;
  padding: 7px;
  border: 1px solid #0a4f73;
}

.ml-feml-itm {
  padding: 8px;
  background: transparent;
  border: none;
  border-bottom: 1px solid #bbbbbb;
  width: 100%;
}

.price-details-dtls h5 {
  color: #000000;
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 15px;
}
.price-details-dtls h4 {
  color: #68707d;
  font-weight: 600;
  font-size: 18px;
}
.ordr-info-part h2 {
  font-size: 32px;
  color: #1d2025;
  font-weight: 600;
}

.tablts-dtls{
  font-size: 16px;
  color: #212529;
  line-height: 1.5;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.shipng-address h5 {
  color: #000000;
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 12px;
}
.shipng-address p {
  color: #777777;
  font-size: 14px;
  font-weight: 400;
}
.shipng-address h6 {
  color: #000000;
  font-weight: 500;
  font-size: 16px;
}
.clearfilter{
  margin-left: 20px;
}
.dashboardheading{
  margin-bottom: 16px;
}
.text-link{
  color: #1783e9;
}

